import React from "react";
import { Helmet } from "react-helmet";

// Define a reusable Meta component that sets page metadata
const Meta = ({ title, description, keywords }) => (
  <Helmet>
    {/* Set the page title based on the 'title' prop */}
    <title>{title}</title>
    {/* Set the meta description based on the 'description' prop */}
    <meta name="description" content={description} />
    {/* Set the meta keywords based on the 'keywords' prop */}
    <meta name="keywords" content={keywords} />
  </Helmet>
);

export default Meta;