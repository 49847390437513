import React from 'react';
import {Link} from "react-router-dom";


const SidebarTag = ({ tags = [] }) => {
  return (
    <ul className="tagcloud">
      {tags.map((tag) => {
        return(
          <Link key={tag.slug} to={process.env.PUBLIC_URL + `/Posts?tag=${tag.title}`}>{tag.title}</Link>
        )
      })}
    </ul>
  )
}

export default SidebarTag
