import React from "react";

const CopyrightTwo = ({copyright}) => {
  const handlePreferenceClick = () => {
    // Implement the window.displayPreferenceModal() function here
    window.displayPreferenceModal();
  };

  return (
    <div className="copyright-area copyright-style-one variation-one">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li>
                  <a href="#"
                    onClick={handlePreferenceClick}
                    id="termly-consent-preferences">Cookie Preferences
                  </a>
                </li>
                <li><a href="/Contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-5 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                <li><Link to="facebook.com"><FiFacebook /></Link></li>
                                <li><Link to="twitter.com"><FiTwitter /></Link></li>
                                <li><Link to="instagram.com"><FiInstagram /></Link></li>
                                <li><Link to="linkdin.com"><FiLinkedin /></Link></li>
                            </ul>
                        </div>
                    </div> */}
          <div className="col-lg-4 col-md-3 col-sm-12 col-12 mt_md--20 mt_sm--20">
            <div className="copyright-right text-center text-lg-right">
              <p className="copyright-text">{copyright}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CopyrightTwo;