import React, { useEffect, useRef, useState } from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { getImg } from '../../services';
import CustomIconLink from '../../components/cutomIcon';
import { getSizeOfBiggerElement } from '../../utils';

const ServiceOne = ({ textAlign, serviceStyle, serviceList = [] }) => {
  const parentElem = useRef(null);
  const [size, setSize] = useState(0);

  const measureSize = () => {
    setSize(0);
    (() => {
      if (parentElem.current && !size) {
        const allChildren = Array.from(parentElem.current.children);
        const newSize = getSizeOfBiggerElement(allChildren);
        setSize(newSize);
      }
    })()
  };
  useEffect(() => {

    if (serviceList?.length) setTimeout(() => measureSize(), 0); // Initial measurement

    window.addEventListener('resize', measureSize);

    return () => {
      window.removeEventListener('resize', measureSize);
    };
  }, [serviceList]);

  return (
    <div className="row row--15 service-wrapper" ref={parentElem}>
      {serviceList.map( (val , i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
            <div 
              className={`service ${serviceStyle} ${textAlign}`} 
              style={size ? { height: `${size}px`, position: 'relative' } : {}}
            >
              <div className="icon" style={{ backgroundColor: val.bgColor || 'transparent'}}>
                <CustomIconLink url={getImg(val.img)} />
              </div>
              <div className="content">
                <h4 className="title w-600"><Link to={val.link} dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.text}}></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  )
}
export default ServiceOne;