import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import IconWithImage from '../CustomIcon';
import { getImg } from './../../services/index';

const ContactOne = ({fullForm, pageInfo, noMap, noInfo}) => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            {!noInfo && (
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <IconWithImage img={pageInfo?.phoneSectionIcon && getImg(pageInfo?.phoneSectionIcon)} />
                    </div>
                    <div className="inner">
                      <h4 className="title">{pageInfo?.phoneTitle}</h4>
                      <p><a href="tel:+1 484 813 7000">{pageInfo?.phoneNumber}</a></p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <IconWithImage img={pageInfo?.emailSectionIcon && getImg(pageInfo?.emailSectionIcon)} />
                    </div>
                    <div className="inner">
                      <h4 className="title">{pageInfo?.emailTitle}</h4>
                      <p><a href="mailto:sales@teknikos.com">{pageInfo?.email}</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <IconWithImage img={pageInfo?.ourLocationSectionIcon && getImg(pageInfo?.ourLocationSectionIcon)} />
                    </div>
                    <div className="inner">
                      <h4 className="title">{pageInfo?.ourLocationTitle}</h4>
                      {pageInfo?.location?.map(i => <p key={i}>{i}</p>)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={`row mt--40 row--15 ${noMap && 'justify-content-center d-flex'} `}>
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" fullForm={fullForm} btnToCenter={noMap && noInfo} />
        </div>
        {!noMap && <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle pageInfo={pageInfo} />
        </div>}
      </div>
    </>
  )
}
export default ContactOne;