import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";
import { getImg } from '../../services';

const Data = [
  {
    number : 199,
    title: 'Happy Clients.',
  },
  {
    number : 575,
    title: 'Employees',
  },
  {
    number : 69,
    title: 'Useful Programs',
  },
  {
    number : 500,
    title: 'Useful Programs',
  },
];

const SlipTwo = ({ title, description, noCountNumber, data = Data, img, descriptionTwo, titleTwo }) => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail">
              <img src={img ? getImg(img) : ''} alt="split Images" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                <h4 className="title">{title || "We're your partner in success and innovation"}</h4>
              </ScrollAnimation>
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                <p className="description">{description || "When you choose Teknikos, you're selecting more than just a service provider – you're forging a strategic partnership. Our 20-year track record proves a history of excellence in delivering impactful solutions. With a commitment to innovation powered by a team of seasoned experts, Teknikos delivers results by tailoring our services to your goals and objectives. With Teknikos you're not just a client; you're a valued partner on the path to success."}</p>
              </ScrollAnimation>
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                {titleTwo && <h4 className="title">{titleTwo || "We're your partner in success and innovation"}</h4>}
              </ScrollAnimation>
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                {descriptionTwo && <p className="description">{descriptionTwo}</p>}
              </ScrollAnimation>
              {!noCountNumber && <div className="row">
                {data.map((data, index) => (
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                    <div className="count-box counter-style-4 text-start">
                      <TrackVisibility once>
                        {({ isVisible }) => isVisible && 
                                                        <div className="count-number">{isVisible ? <CountUp end={data.number} /> : 0}</div>}
                      </TrackVisibility>
                      <h5 className="counter-title">{data.title}</h5>
                    </div>
                  </div>
                ))}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SlipTwo;