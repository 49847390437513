import React from "react"
import BlogListView from "../components/blog/BlogListView";
import Meta from "../components/Meta";
import usePageInfo from "../common/hooks";
import { getAllBlogs } from "../services";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const compareTimestamps = (a, b) => new Date(b.date) - new Date(a.date);

const Posts = () => {
  const { pageInfo } = usePageInfo('Posts', 'gFTm8CgzCLrHS3WOR66u');
  const [blogs, setBlogs] = useState([]);
  const [initialBlogs, setInitialBlogs] = useState([]);
  const [tag, setTag] = useState('');
  const location = useLocation();

  useEffect(() => {
    (async() => {
      const data = await getAllBlogs()
      if(!initialBlogs.length) {
        setInitialBlogs(data);
      }
      if(location.search) {
        const decodedTag = decodeURIComponent(location.search.replace('?tag=', ''));
        setTag(decodedTag);
        const filteredData = data
          .filter(item => item.tags.some(tag => tag.title === decodedTag))
          .slice().sort(compareTimestamps);
        setBlogs(filteredData);
      } else {
        const sortedData = data.slice().sort(compareTimestamps);
        setBlogs(sortedData);
        setTag('');
      }
    })();
  },[]);

  useEffect(() => {
    if(!location.search && initialBlogs.length) {
      setBlogs(initialBlogs)
      setTag('');
    }
  },[location.search]);

  return <>
    <Meta
      title="The Teknikos Take - Exploring Technology Trends and Innovations"
      description="Explore the Teknikos Take, where we share our insights on the latest technologies, trends, and innovations shaping our world, from practical to fantastic."
      keywords="The Teknikos Take, Technology Trends, Innovation Insights, Tech News, Emerging Technologies, Industry Trends, Practical Solutions, Futuristic Innovations, Technology Insights, Digital Transformation, Tech Trends Analysis, Thought Leadership, Expert Opinions, Industry Updates, Technology Blog, Innovation Commentary, Teknikos Blog, Tech World Trends, Future Technology"
    />
    <BlogListView hideBreadcrump={!location.search} title={tag || pageInfo?.title} blogs={blogs} description={tag ? '' :pageInfo?.text} sympleFooter />
  </>
}

export default Posts;