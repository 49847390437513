import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Markdown from 'markdown-to-jsx';
import usePageInfo from '../common/hooks';

const PrivacyPolicy = () => {
  const { pageInfo } = usePageInfo('PrivacyPolicy', 'g1jZIfk3qBpuvdubaorf');
  return (
    <>
      <SEO title="Privacy Policy" />
      <Layout sympleFooter>
        <div className="blog-details-content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <Markdown>{pageInfo?.content || ''}</Markdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default PrivacyPolicy;