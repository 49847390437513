import React, { useEffect, useState } from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    title: "Screen & Assess",
    description: "Professional qualifications and communication skills are evaluated, technical assessment completed to categorize role, level, and placement fit criteria.",
  },
  {
    id: "2",
    title: "References & Compliance",
    description: "Work history, experience, education, and certifications are validated through reference and background checks. Legal and regulatory requirements verified.",
  },
  {
    id: "3",
    title: "Technical Interviews & Profile",
    description: "Subject matter experts complete interviews and document profile to finalize role and level alignment and identify soft skills, cultural fit assessment, and personal development.",
  },
  {
    id: "4",
    title: "Monitoring & Development",
    description: "Regular evaluations and personal development plans ensure resource profiles are accurate and reflect professional performance and growth.",
  },
]

const TimelineTwo = ({classVar, timelineData=TimelineData}) => {
  // eslint-disable-next-line no-unused-vars
  const [maxContainer, setMaxContainer] = useState(0);
  const getMaxHeights = () => {
    setMaxContainer(0)
    const elements = document.getElementsByClassName('titleForSize');
    const elementArray = Array.from(elements);
    const heights = Math.max(...elementArray.map(element => element.clientHeight));
    setMaxContainer(heights)
  }

  useEffect(() => {
    getMaxHeights();
    window.addEventListener('resize', getMaxHeights);

    return () => {
      window.removeEventListener('resize', getMaxHeights);
    }
  }, []);

  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {timelineData.map((data , index) =>(
          <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
            <div className="rn-timeline">
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                <h6 {...(maxContainer ? {style: { height: `${maxContainer}px` }} : {})} className="title titleForSize">{data.title}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                <p className="description">{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  )
}
export default TimelineTwo;