import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import LogoSVG from "../../assets/images/logo/TEK_logo.svg";

const Logo = () => {
  return(
    <div className="logo">
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img className="logo-light" src={LogoSVG} alt="Corporate Logo" />
        <img className="logo-dark" src={LogoSVG} alt="Corporate Logo" />
      </Link>
    </div>
  )
}
Logo.propTypes = {
  image: PropTypes.string
};

export default Logo;
