import React from 'react';
import HeaderOne from "./header/HeaderOne";
import FooterThree, { FooterWrapper } from './footer/FooterThree';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';

const Layout = ({children, noHeader}) => {
  return (
    <>
      <main className="page-wrapper">
        {!noHeader && <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />}
        {children}
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionThree} />
      </main>
    </>
  )
}
export default Layout;
