import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogPropTwo from './itemProp/BlogPropTwo';

const BlogListView = ({ sympleFooter, title, description, blogs, hideBreadcrump }) => {
  return (
    <>
      <SEO title="Blog List View || Doob - React Business  Template" />
      <Layout sympleFooter={sympleFooter}>
        <BreadcrumbOne 
          title={title}
          description={description}
          noPageInfo={hideBreadcrump}
          rootUrl="/Posts"
          parentUrl="All Posts"
        />
                 
        <div className="main-content">
          {/* Start Blog Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <BlogPropTwo data={blogs} column="col-lg-6 mt--30" StyleVarProp="box-card-style-default card-list-view" />
              </div>
            </div>
          </div>
          {/* End Blog Area  */}
        </div>

      </Layout>
    </>
  )
}

export default BlogListView
