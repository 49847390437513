import { getFirestore, collection, query, getDocs, doc, getDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// Note: When merging with master, use the PROD Firebase configuration
// The DEV Firebase configuration is only for local and development environment purposes
// The values in firebase.client.js are not used.

// PROD Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCwvrZsKCtjbgDg2_dR3-zvL0Yd2J1Q7b8",
//   authDomain: "teknikos-admin-fbt25.firebaseapp.com",
//   projectId: "teknikos-admin-fbt25",
//   storageBucket: "teknikos-admin-fbt25.appspot.com",
//   messagingSenderId: "1041305460517",
//   appId: "1:1041305460517:web:54316642e65c3b20d684cc"
// };

// DEV Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvkRdJ3fvBePw_fgHF9QfKLlXEe2t84QI",
  authDomain: "teknikos-website-dev.firebaseapp.com",
  projectId: "teknikos-website-dev",
  storageBucket: "teknikos-website-dev.appspot.com",
  messagingSenderId: "926812727451",
  appId: "1:926812727451:web:323998a714e05ef859fe3e"
};

// Initialize Firebase app with your configuration
const app = initializeApp(firebaseConfig);

// Get a Firestore instance
const db = getFirestore(app);

// Example usage
export async function getDataFromDb({
  collectionName,
  documentId,
}) {
  try {
    const documentRef = doc(db, collectionName, documentId);
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      // Access the document data
      const data = documentSnapshot.data();
      return data;
    } else {
      console.log("Document does not exist.");
      return {};
    }
  } catch (error) {
    console.error('DB error', error);
  }
}

export const getAllBlogs = async () => {
  try {
    // Create a query for the 'Blogs' collection
    const q = query(collection(db, 'Blogs'));
    
    // Use getDocs to fetch the documents
    const blogsCollection = await getDocs(q);

    // Map the data directly to an array of objects
    const blogs = blogsCollection.docs.map(doc => doc.data());

    return blogs;
  } catch (error) {
    console.error('Error getting blogs:', error);
    return [];
  }
};

export const getAllFooterTopSections = async () => {
  try {
    // Create a query for the 'Blogs' collection
    const q = query(collection(db, 'FooterTopSection'));
    
    // Use getDocs to fetch the documents
    const blogsCollection = await getDocs(q);

    // Map the data directly to an array of objects
    const blogs = blogsCollection.docs.map(doc => doc.data());

    return blogs;
  } catch (error) {
    console.error('Error getting Footer top sections:', error);
    return [];
  }
};

// Note: When merging with master, use the PROD Firebase configuration
// The DEV Firebase configuration is only for local and development environment purposes

// PROD
// export const getImg = (url = '', bg) =>  bg 
//   ? `url(https://firebasestorage.googleapis.com/v0/b/teknikos-admin-fbt25.appspot.com/o/${url?.replace('images/', 'images%2F')}?alt=media&token=952fbe71-131d-4b05-92d4-25d5170a3c76)`
//   : `https://firebasestorage.googleapis.com/v0/b/teknikos-admin-fbt25.appspot.com/o/${url?.replace('images/', 'images%2F')?.replace('image/', 'image%2F')}?alt=media&token=952fbe71-131d-4b05-92d4-25d5170a3c76`;

// DEV
export const getImg = (url = '', bg) =>  bg 
  ? `url(https://firebasestorage.googleapis.com/v0/b/teknikos-website-dev.appspot.com/o/${url?.replace('images/', 'images%2F')}?alt=media&token=952fbe71-131d-4b05-92d4-25d5170a3c76)`
  : `https://firebasestorage.googleapis.com/v0/b/teknikos-website-dev.appspot.com/o/${url?.replace('images/', 'images%2F')?.replace('image/', 'image%2F')}?alt=media&token=952fbe71-131d-4b05-92d4-25d5170a3c76`;
