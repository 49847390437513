import React, { useEffect, useState } from "react";
import Contact from "../elements/contact/Contact";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import { Helmet } from 'react-helmet';
import SEO from "../common/SEO";
import Meta from "../components/Meta";
import usePageInfo from "../common/hooks";
import { getImg } from "../services";
import Markdown from 'markdown-to-jsx';

const config = {
  digitalStrategyRoadmap: {
    meta: {
      title: "Digital Strategy Roadmap",
      description: "Explore the Digital Strategy Roadmap, your key to successful digital evolution. Discover how our customized services and expert guidance drive technology evaluation, strategic execution, and deployment planning",
      keywords: "Digital Strategy Roadmap, Digital Transformation, Technology Evaluation, Strategic Execution, Deployment Planning, Business Growth, Operational Goals, Efficiency Optimization, Customer Experience Enhancement, Comprehensive Strategic Plan, Digital Landscape Assessment, Technology Recommendations, Implementation Program Plan, Cost-Benefit Analysis, Adoption and Change Management, 3-Week Program, Financial Predictability, Program Output Transparency, Customized Services, Digital Initiatives Alignment, Business Goals Alignment, Drive Efficiency, Reduce Costs, Customer-Centric Approach, Technology Integration, Digital Footprint Assessment, Strategic Guidance, Digital Evolution, Roadmap for Success"
    },
    id: "j4UwEpe9Qa21Ui9PM8Gi"
  },
  aIIntegrationNavigator: {
    meta: {
      title: "AI Integration Navigator - Optimize with Scalable AI Integrations by Teknikos",
      description: "Explore the AI Integration Navigator program, leading organizations in AI technology evaluation, prioritization, and implementation planning. Teknikos helps identify AI optimization opportunities and develop strategic plans for AI integration.",
      keywords: " AI Integration Navigator, Artificial Intelligence Integration, AI Adoption, Technology Evaluation, Prioritization, Implementation Planning, Strategic AI Integration, Scalable AI Integrations, AI Optimization, AI Use Cases, AI Technologies, AI Implementation Plan, Financial Predictability, Program Output Transparency, Strategic Planning, AI Readiness Assessment, Adoption Readiness, AI Adoption Program, Cost-Benefit Analysis, Change Management Strategies, Optimize with AI, Scalable AI Technologies, AI Strategic Plan, Business and Strategic Goals, Streamlined Project Experience"
    },
    id: "41tDjfWJ0opiv9DAo1bI",
  },
  immersiveTechnologyEnvisioning: {
    meta: {
      title: "Immersive Technology Envisioning - Harness Immersive Tech for Engaging Experiences",
      description: "Explore Immersive Technology Envisioning, the service that explores and conceptualizes high-impact applications of immersive technologies like VR, AR, and MR. Create engaging digital experiences that bring your brand to life with Teknikos.",
      keywords: "Immersive Technology Envisioning, Immersive Experiences, Virtual Reality (VR), Augmented Reality (AR), Mixed Reality (MR), Immersive Technology Applications, Digital Experiences, Engaging Experiences, Immersive Tech Solutions, Technology Demos, Conceptualization, Use Case Prioritization, Ideation, Concept Development, Immersive Experience Blueprints, Technology Recommendations, Impact Strategy, Execution and Implementation Plan, Brand Engagement, Digital Brand Experiences, Trade Show Booths, Global Product Releases, Teknikos Immersive Technology, Brand Enhancement, Digital Space Impact, High-Impact Immersive Tech"
    },
    id: "aSySeDRX2P7GSDk5W9gm"
  },
  softwareDevelopmentIncubator: {
    meta: {
      title: "Software Development Incubator | Teknikos",
      description: "Propel your software vision through Teknikos' Software Development Incubator. Jumpstart your project with a multidisciplined team, planning, and prototype development.        ",
      keywords: "Software Development Incubator, Software Prototype Development, Planning and Prototype, Multidisciplined Team, Digital Strategy and Engineering, Jumpstart Software Development, Technology Assessments, Project Planning, Requirements Gathering, Project Scope, Conceptual Viability, Development Documentation, Initial Prototype, Project Implementation, Teknikos Incubator Program, Software Project Planning, Jumpstart Software Projects, Hybrid Service Program, Technical and Conceptual Viability, Teknikos Software Services"
    },
    id: "RUZQ1IVc5l4iJibBHbZc"
  },
  customSoftwareDevelopment: {
    meta: {
      title: "Custom Software Development Services | Teknikos",
      description: "Explore Teknikos' Custom Software Development services. Our expert engineering teams bring your unique business ideas to life. Get started on your project today.",
      keywords: "Custom Software Development, Bespoke Software Solutions, Tailored Software Applications, Unique Business Requirements, Custom Software Engineers, Agile Software Development, Transparent Pricing, Scalable Software Solutions, Deployable Software, Development Documentation, User Documentation, Ongoing Support, Teknikos Software Services, Teknikos Custom Development, Software Development Program, Predictable Development Process, Get Started with Teknikos, Contact Teknikos, Customized Software Solutions, Collaborative Development"
    },
    id: "p62vOI9hB9Tk6Za4QcuT"
  },
  aIIntegrationandDevelopment: {
    meta: {
      title: "AI Integration and Development Services | Teknikos",
      description: "Explore Teknikos' AI Integration and Development services. Harness the power of AI to supercharge your business with AI-powered applications and intelligent automations. Get started on your project today.",
      keywords: "AI Integration and Development, Artificial Intelligence Applications, AI-Powered Software, AI Technologies for Business, Smart Assistants, Building Intelligent Automations, Decision-Making with AI, Automation with AI, Enhanced User Experiences, Architecting AI Solutions, Agile Development Process, Data Estate Integration, AI Use Cases, Model Training and Optimization, Deployable AI Software, AI Development Documentation, User Documentation for AI, Ongoing AI Support, Teknikos AI Services, Teknikos Development Programs"
    }, 
    id: "7JuqbJFCsStumMGGKGha"
  },
  emergingAndImmersiveExperiences: {
    meta: {
      title: "Emerging and Immersive Experiences | Teknikos",
      description: "Explore Teknikos' Emerging and Immersive Experiences. Elevate your brand, train your team, and captivate your audience with immersive technologies. Learn more about our large format, environmental, virtual reality (VR), augmented reality (AR), and mixed reality (MR) solutions.",
      keywords: "Emerging and Immersive Experiences, Immersive Technologies, Large Format Experiences, Environmental Experiences, Virtual Reality (VR), Augmented Reality (AR), Mixed Reality (MR), Immersive Solutions, Concept Implementation, Deployment of Immersive Technologies, Digital and Physical Space, Innovation with Immersive Tech, Immersive Technology Development, Rapid Build and Test Cycles, Solution Durability, Deployable Immersive Software, Immersive Development Documentation, User Documentation for Immersive Tech, Installation and Implementation of Immersive Solutions, Ongoing Support for Immersive Experiences"
    },
    id: "SwC3L6eI3aOlwP910gyb"
  },
  digitalSignage: {
    meta: {
      title: "Digital Signage Solutions | Teknikos",
      description: "Discover Teknikos' Digital Signage Solutions. Engage your audience at the highest point of impact with dynamic and interactive digital signage, large format displays, and broadcast messaging. Learn how we lead in hardware curation, software development, and content management for digital signage.",
      keywords: "Digital Signage Solutions"
    }, 
    id: "lz0GcoPQHVvdhtvkvZPi"
  },
  resourceScaling: {
    meta: {
      title: "AI Integration Navigator - Optimize with Scalable AI Integrations by Teknikos",
      description: "",
      keywords: ""
    },
    id: "GywJEW80vuYEay4UEc3C"
  },
  specialityResources: {
    meta: {
      title: "Specialty Resources | Staff Augmentation | Teknikos",
      description: "Discover Teknikos' specialty resource placement services. Find highly specialized professionals with niche skills and expertise to meet your specific project requirements. Get expert guidance and problem-solving solutions for complex challenges.",
      keywords: "Specialty Resources, Specialized Professionals, Niche Skills, Expertise, Targeted Solutions, Complex Challenges, Technical Experts, Subject Matter Experts, Specialty Resource Placement, Skill Sets, Project Requirements, Problem-Solving, Expert Guidance, Specialized Services, Staff Augmentation, Resource Placement Services, Project Outcomes, Teknikos Specialty Resources, Specialized Solutions, Expert Professionals, Professional Expertise, Staffing Services, Targeted Geographies, Sourcing and Placement Services, Contact Teknikos"
    },
    id: "3eCHb82XDj0WdgZJJvAH"
  },
  offshoreDevelopment: {
    meta: {
      title: "Offshore Development Teams | Staff Augmentation | Teknikos",
      description: "Explore Teknikos' offshore development teams and staff augmentation services. Build global talent teams to accelerate your projects while keeping costs competitive. Get the right resources to streamline development",
      keywords: "Offshore Development Teams, Staff Augmentation, Global Talent, Offshore Resources, Augment and Accelerate Projects, Cost-Effective Development, Global Project Teams, Offshore Locations, Dedicated Offshore Teams, Skilled Resources, Ongoing Evaluation, Resource Alignment, Resource Categories, Software Development Engineers, Project Managers, Quality Assurance Testers, UX/UI Designers, Specialty Resources, Teknikos Offshore Development, Offshore Talent, Global Workforce, Staffing Services, Cost-Competitive Solutions, Project Acceleration, Team Augmentation, Project Productivity, Offshore Project Teams, Professional Resources, Development Process, Contact Tekniko"
    }, 
    id: "Som9pyseg6D02in3sqKy"
  },
  devOps: {
    meta: {
      title: "Digital Signage Solutions | Teknikos",
      description: "Discover Teknikos' Digital Signage Solutions. Engage your audience at the highest point of impact with dynamic and interactive digital signage, large format displays, and broadcast messaging. Learn how we lead in hardware curation, software development, and content management for digital signage.",
      keywords: "Digital Signage Solutions, Dynamic Digital Signage, Interactive Digital Kiosks, Large Format Displays, Broadcast Messaging, Hardware Curation, Software Development for Digital Signage, Content Management Systems, Interactive Content Delivery, Seamless Signage Implementation, Digital Signage Deployment, Content Management and Delivery, Hardware Procurement, Installation of Digital Signage, Networking for Digital Signage, Change Management for Signage, Deployable Signage Software, Digital Signage Documentation, User Documentation for Signage, Hardware Sourcing and Procurement, On-site Testing for Signage, Ongoing Support for Digital Signage, Signage Maintenance Plans, Digital Signage Management"
    },
    id: "zN3JZOajldawoLWMn5qY",
  }
};

const Product = ({ pageName }) => {
  const [pageInfo, setPageInfo] = useState({});
  const { pageInfo: info } = usePageInfo('Product', config[pageName].id);
  useEffect(() => {
    if (!Object.values(pageInfo)?.length && Object.values(info)?.length) {
      const newConfig = {...config};
      newConfig[pageName] = {...info, ...newConfig[pageName]}
      setPageInfo({...newConfig});
    }
  },[info, pageInfo, pageName]);
  return !Object.values(pageInfo).length ? "" : (
    <main className="page-wrapper">
      <SEO title={pageInfo?.[pageName]?.title} />
      <Meta 
        title={(info?.meta?.title || pageInfo?.[pageName]?.meta.title)}
        description={(info?.meta?.description || pageInfo?.[pageName]?.meta?.description)}
        keywords={(info?.meta?.keywords || pageInfo?.[pageName]?.meta?.keywords)}
      />
      <Helmet>
        <title>{(info?.meta?.title || pageInfo?.[pageName]?.meta?.title)}</title>
        <meta
          name="description"
          content={(info?.meta?.description || pageInfo?.[pageName]?.meta?.description)}
        />
        <meta name="keywords" content={(info?.meta?.keywords || pageInfo?.[pageName]?.meta?.keywords)} />
      </Helmet>
      <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
      {/* Start Slider Area  */}
      <div className="slider-area slider-style-6 shape-right height-650">
        <div className="container">
          <div className="row row--30 align-items-center">
            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
              <div className="inner text-start">
                <h1 className="title color-white">{pageInfo?.[pageName]?.title}</h1>
                <p className="description color-white">
                  <Markdown>{pageInfo?.[pageName]?.text}</Markdown>
                </p>
              </div>
            </div>

            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail">
                <img src={pageInfo?.[pageName]?.img ? getImg(pageInfo?.[pageName]?.img) : ''} alt="Banner Images" />
              </div>
            </div>
            <div className="shape-image">
              {/* <img src={getImg(pageInfo?.[pageName]?.section3Img)} alt="Banner Images" /> */}
            </div>
          </div>
        </div>
      </div>
      {/* End Slider Area  */}
      {/* Start Service Area  */}
      <div className="rn-service-area rn-section-gap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="content">
                <h3 className="title">{pageInfo?.[pageName]?.section2Title}</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <p className="no-margin-on-desktop" dangerouslySetInnerHTML={{__html: pageInfo?.[pageName]?.section2Text}} />

            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}
      {/* Start Elements Area  */}
      <div className="rwt-advance-tab-area rn-section-gap">
        <div className="container">
          <div className="row mb--40">
            <div className="col-lg-12">
              <SectionTitle
                textAlign = "text-center"
                radiusRounded = ""
                subtitle = {pageInfo?.[pageName]?.section3Headline}
                title = {pageInfo?.[pageName]?.section3Title}
                description = ""
              />
            </div>
          </div>
          <AdvanceTabOne withText tabs={pageInfo?.[pageName]?.section3Tabs || []} />
        </div>
      </div>
      {/* End Elements Area  */}
      <Contact noHeader isProduct noInfo noMap />
    </main>
  )};

export default Product;