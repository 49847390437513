import React from 'react'
import Layout from "../common/Layout";

const Error = ({ title='404', subTitle='Your page cannot be found.', btnText='Go Back Home',}) => {
  return (
    <Layout>
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h1>{title}</h1>
                <h2 className="title">{subTitle}</h2>
                {/* <p className="description"> By injectedeed eedhumour, or randomised words which don't look even .</p> */}
                <div className="view-more-button">
                  <a className="btn-default" href="/">{btnText}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Error;