import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { getImg } from '../../services';
import { getSizeOfBiggerElement } from '../../utils';

const ServiceTwo = ({ textAlign, cardStyle, serviceList = [], grid }) => {
  const parentElem = useRef(null);
  const [size, setSize] = useState(0);

  const measureSize = useCallback(() => {
    setSize(0);
    (() => {
      if (parentElem.current && !size) {
        const allChildren = Array.from(parentElem.current.children);
        const newSize = getSizeOfBiggerElement(allChildren);
        setSize(newSize);
      }
    })()
  }, [parentElem.current]);
  useEffect(() => {

    if (serviceList?.length) setTimeout(() => measureSize(), 200); // Initial measurement

    window.addEventListener('resize', measureSize);

    return () => {
      window.removeEventListener('resize', measureSize);
    };
  }, [serviceList]);

  return (
    <div className={`row row--15 service-wrapper rn-section-gap-top ${grid && 'gridrow'}`} ref={parentElem}>
      {serviceList.map((val, i) => (
        <div
          className={`col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 ${grid && 'gridItem'}`}
          key={i}
        >
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div
              className={`card-box ${cardStyle} ${textAlign}`}
              style={size ? { height: `${size}px`, position: 'relative' } : {}}
            >
              <div className="inner">
                <div className="image">
                  <Link to={val.link}>
                    <img
                      src={(val?.image || val?.img) ? getImg((val?.image || val?.img)) : ''}
                      alt="card Images"
                      onLoad={() => window.requestAnimationFrame(() => measureSize())}
                    />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <Link
                      to={val.link}
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description || val.text }}
                  ></p>
                  <Link
                    className={`btn-default btn-small btn-border ${size ? 'btn-small-to-bottom' : ''}`}
                    to={val.link}
                  >
                    {val.btnText || 'Learn More'}
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default ServiceTwo;
