import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BrandThree from '../elements/brand/BrandThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlipTwo from '../elements/split/SlipTwo';
import SlipFour from '../elements/split/SlipFour';
import TeamTwo from '../elements/team/TeamTwo';
import FooterThree, { FooterWrapper } from '../common/footer/FooterThree';
import Meta from '../components/Meta';
import usePageInfo from '../common/hooks';
import { getImg } from '../services';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';

const AboutUs = () => {
  const { pageInfo } = usePageInfo('AboutUs', 'qBSLjpka0Jml3F6GGfKN');
  return (
    <>
      <Meta
        title="About Teknikos - Leading Businesses into the Future"
        description="Learn about Teknikos, where we lead businesses to what's coming next. Discover our innovative solutions and commitment to shaping the future."
        keywords="Teknikos History, Visionary Technology Company, Boundless Innovation, Technology Solutions, Digital Transformation, Business Empowerment, Tech-Driven Future, Excellence and Integrity, Passionate Team, Corporate Commitment, Innovation in Business, Customer Success Stories, Collaboration with Partners, Leadership Insights, Meet Our Team, Corporate Leadership, Technology Innovations, Innovation and Excellence, Digital Business Growth, Empowering Organizations"
      />
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: pageInfo?.bgImg ? getImg(pageInfo?.bgImg, true) : ""}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge"><span className="theme-gradient">{pageInfo?.orangeTitle}</span></h3>
                  </div>
                  <h1 className="title display-one">{pageInfo?.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <SlipTwo title={pageInfo?.whoWeAre?.title} titleTwo={pageInfo?.whatWeDo?.title} noCountNumber descriptionTwo={pageInfo?.whatWeDo?.text} description={pageInfo?.whoWeAre?.text} img={pageInfo?.whoWeAre?.img} />
          </div>
        </div>
        {/* End Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <SlipFour data={pageInfo?.ourCommitment?.statistics} title={pageInfo?.ourCommitment?.title} description={pageInfo?.ourCommitment?.text} img={pageInfo?.ourCommitment?.img}/>
          </div>
        </div>

        {/* Start Brand Area  */}
        <div className="rwt-brand-area pb--60 pt--30">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign = "text-center"
                radiusRounded = ""
                subtitle = {pageInfo?.theCompaniesWeKeep?.orangeTitle}
                title = {pageInfo?.theCompaniesWeKeep?.title}
                description = {pageInfo?.theCompaniesWeKeep?.text}
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandList={pageInfo?.theCompaniesWeKeep?.list} brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.leadership?.orangeTitle}
                  title = {pageInfo?.leadership?.title}
                  description = ""
                />
              </div>
            </div>
            <TeamTwo 
              teamData={pageInfo?.leadership?.list}
              column="col-lg-4 col-md-6 col-12 mt--10" 
              teamStyle="team-style-default style-two" 
            />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionThree} />
      </main>
    </>
  )
}

export default AboutUs;
