import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getImg } from '../../../services';


const BlogList = ({ data, StyleVar, id }) => {
  const addToStore = () => {
    sessionStorage.setItem('blog', JSON.stringify(data));
  };
  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link to={`/blog-details?id=${id}`} onClick={addToStore} className="image">
            <img src={data.img ? getImg(data.img) : ''} alt="Blog" />
          </Link>
        </div>
        <div className="content">
          <h4 className="title">
            <Link to={`/blog-details?id=${id}`} onClick={addToStore}>
              {data.title}
            </Link>
          </h4>
          <ul className="rainbow-meta-list">
            {!!data?.author && <li>{data?.author}</li>}
            {!!data?.date?.seconds && <li>{new Date(data?.date?.seconds * 1000).toLocaleDateString()}</li>}
          </ul>
        </div>
      </div>
    </div>
  )
}
BlogList.propTypes = {
  data: PropTypes.object
};
export default BlogList;
