import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import Meta from '../../components/Meta';
import usePageInfo from '../../common/hooks';

const Contact = ({ noHeader, fullForm = true, isProduct, noMap = true, noInfo }) => {
  const { pageInfo } = usePageInfo(isProduct ? 'ProductContactUs' : 'ContactUs', isProduct ? 'WGN7aXsaRpyCUpBVtVcC' : '516G5uLw3MwJFf7K3TWL');
  return (
    <>
      {!isProduct && <><SEO title="Contact" />
        <Meta
          title="Contact Us - Get in Touch with Teknikos"
          description="Contact Teknikos using the provided phone, email, or address. We're here to assist you with your inquiries, product details, and more."
          keywords="Teknikos, Contact Us, Get in Touch, Phone Contact, Email Contact, Headquarters Address, Hatfield PA Location, Teknikos Locations, Contact Information, Inquiry Form, Product Inquiries, Sales Contact, General Inquiries, Contact Teknikos, Global Locations, Teknikos Office Locations, Contact Information, Inquiry Submission, Contact Teknikos Team, Map with Locations"
        /></>}
      <Layout noHeader={noHeader} sympleFooter>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = {pageInfo?.orangeTitle}
                    title = {pageInfo?.title}
                    description = {pageInfo?.copy}
                  />
                </div>
              </div>
              <ContactOne pageInfo={pageInfo} fullForm={fullForm} noMap={noMap} noInfo={noInfo} />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  )
}
export default Contact;