import React from 'react';
import SidebarTag from './sidebar/SidebarTag';
import { FiUser, FiCalendar } from "react-icons/fi";
import { getImg } from './../../services/index';
import Markdown from 'markdown-to-jsx';

const BlogDetailsContent = ({ data }) => {
  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient">{data.title}</h1>
                </div>
                <ul className="rn-meta-list">
                  {!!data.author && <li><FiUser />{data.author}</li>}
                  {!!data?.date?.seconds && <li><FiCalendar />{new Date(data?.date?.seconds * 1000).toLocaleDateString()}</li>}
                </ul>
                <div className="thumbnail alignwide mt--60">
                  {!!data?.img && <img className="w-100 radius" src={data.img ? getImg(data.img) : ''} alt="Blog Images" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                {data?.copy && <Markdown>{data?.copy}</Markdown>}
                {!!data?.tags?.length &&<div className="category-meta d-block">
                  <span className="text">Tags: <br /></span>
                  <SidebarTag tags={data.tags} />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

                              



    </>
  )
}
export default BlogDetailsContent;
