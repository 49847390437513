import React from 'react'
import FooterThree, { FooterWrapper } from '../common/footer/FooterThree';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import SlpitOne from '../elements/split/SlpitOne';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';
import SEO from '../common/SEO';
import Meta from '../components/Meta';
import usePageInfo from '../common/hooks';
import { getImg } from '../services';

const HardwareSolutions = () => {
  const { pageInfo } = usePageInfo('HardwareSolutions', 'ryavG8oe79HRxMAwBVsT');
  return (
    <>
      <Meta
        title="Holistic Hardware Solutions - Teknikos"
        description="Explore Teknikos' comprehensive hardware solutions. Directly source off-the-shelf or custom hardware tailored to your needs."
        keywords="Hardware Solutions, Holistic Hardware, Custom Hardware, Hardware Resale, Custom Devices, Hardware Design, Hardware Manufacturing, Logistics Services, Off-the-Shelf Hardware, Bespoke Hardware, Hardware Procurement, Top-Quality Hardware, Tailor-Made Devices, Hardware Vision, Hardware Resale Service, Custom Hardware Design, Custom Hardware Manufacturing, Hardware Logistics"
      />
      <main className="page-wrapper">
        <SEO title="hardware solutions" />
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-850">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h2 className="title theme-gradient" dangerouslySetInnerHTML={{__html: pageInfo?.title}}></h2>
                  <p className="description">{pageInfo?.subTitle}</p>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img src={pageInfo?.bgImg ? getImg(pageInfo?.bgImg) : ''} alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Service Area  */}
        <div className="service-area rn-section-gapBottom rn-section-gap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">{pageInfo?.topSecondSectionTitle}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">{pageInfo?.topSecondSectionText}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.hardwareResale?.orangeTitle}
                  title = {pageInfo?.hardwareResale?.sectionTitle}
                  description = ""
                />
              </div>
            </div>
            <SlpitOne 
              title={pageInfo?.hardwareResale?.title}
              description={pageInfo?.hardwareResale?.text}
              btnText={pageInfo?.hardwareResale?.btnText}
              url={pageInfo?.hardwareResale?.img}
              link="/Contact"
            />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.customDevices?.orangeTitle}
                  title = {pageInfo?.customDevices?.sectionTitle}
                  description = ""
                />
              </div>
            </div>
            <SlpitOne 
              title={pageInfo?.customDevices?.title}
              description={pageInfo?.customDevices?.text}
              btnText={pageInfo?.customDevices?.btnText}
              url={pageInfo?.customDevices?.img}
              link="/Contact"
            />
          </div>
        </div>
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionThree} />
      </main>

    </>
  )
}

export default HardwareSolutions;