import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { getImg } from '../../services';
import Markdown from 'markdown-to-jsx';
import { getImg } from '../../services';

const AdvanceTabOne = ({ tabs, withText }) => {
  return (
    <div>
      <Tabs>
        <div className="row row--30">
          <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
            <div className="advance-tab-button advance-tab-button-1 no-margin">
              <TabList className="tab-button-list">
                {tabs.map(({ title, text }, index) => (
                  <Tab key={index}>
                    <div className="tab-button">
                      <h4 className="title no-margin">{ title }</h4>
                      {!withText && <p className="description "><Markdown>{ text }</Markdown></p>}
                    </div>   
                  </Tab>
                ))}
              </TabList>
            </div>
          </div>
          <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="advance-tab-content advance-tab-content-1">
              {tabs.map(({ text, img}, index) => (
                <TabPanel key={index}>
                  <div className="tab-content">
                    <div className="inner">
                      <div className="thumbnail">
                        {withText ? <p className="description description-to-center"><Markdown>{ text }</Markdown></p> :  <img src={img ? getImg(img) : ''} alt="Corporate Images" />}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  )
}
export default AdvanceTabOne;