import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const GoogleMapStyle = ({ pageInfo }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: 20, // Set the initial center point
    lng: 0,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBI24WuSVdJontXQWAvPo68ye_4ggKDWFQ">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={1} // Adjust the initial zoom level
      >
        {pageInfo?.positionsInMap?.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            label={marker.text}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapStyle;

