import React from "react";
import BlogClassicData from '../../../data/blog/BlogList.json';
import BlogList from "./BlogList";


const alldataBLog = BlogClassicData;
const BlogPropTwo = ({ column , StyleVarProp, data=alldataBLog}) => {

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {data?.map((item) => (
            <div key={item?.title?.replaceAll(' ', '-')} className={column}>
              <BlogList id={item?.title?.replaceAll(' ', '-')} StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
      {/* <div className="col-lg-12">
                <div className="rwt-load-more text-center mt--60">
                    <button
                        className="btn btn-default btn-icon"
                        onClick={handleLoadmorebl}
                        disabled={noMorePost ? "disabled" : null}
                    >
                        {noMorePost ? (
                        "No Post Here"
                        ) : (
                        <span>
                            View More Post 
                            <span className="icon">
                                <FaSpinner />
                            </span>
                        </span>
                        )}
                    </button>
                </div>
            </div> */}
    </>
  )
}
export default BlogPropTwo;