import React from 'react';
import { getImg } from '../../services';

const CalltoActionThree = ({ data }) => {
  return (
    <div className="rn-callto-action clltoaction-style-default style-3 bg_image"  style={{backgroundImage: getImg(data.backgroundImage, true)}}>
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper">
          <div className="col-lg-8">
            <div className="inner">
              <div className="content text-start">
                <h2 className="title">{data.title}</h2>
                <h6 className="subtitle">{data.text}</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="call-to-btn text-start text-lg-right">
              <a className="btn-default" href={data.link || '#'}>{data.btnText}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CalltoActionThree;