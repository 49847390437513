import { useEffect, useState } from 'react';
import { getDataFromDb } from '../../services';

function usePageInfo(collectionName, documentId) {
  const [pageInfo, setPageInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getDataFromDb({ collectionName, documentId });
        setPageInfo(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }

    fetchData();
  }, [collectionName, documentId]);

  return { pageInfo, loading };
}

export default usePageInfo;
