import React from 'react';

const IconWithImage = ({ img }) => {
  return (
    <div>
      <img
        src={img}
        alt="icon"
        style={{ width: '50px', height: '50px', opacity: img ? 1 : 0 }}
      />
    </div>
  );
};

export default IconWithImage;