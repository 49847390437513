import React, { useEffect, useState } from 'react';
import CopyrightTwo from "./CopyrightTwo";
import ScrollTop from "./ScrollTop";
import usePageInfo from '../hooks';
import { useMemo } from 'react';
import { getAllFooterTopSections } from '../../services';


export const FooterWrapper = ({ Comp, TopSection }) => {
  const { pageInfo } = usePageInfo('Footer', 'AWsmUJEJZLyrsWQHcZiv');
  const [ pageTopSectionInfo, setPageTopSectionInfo ] = useState([]);

  useEffect(() => {
    (async() => {
      const data = await getAllFooterTopSections();
      setPageTopSectionInfo(data);
    })();
    //disabled cuz is one time action.
  }, []);

  const getFooterTopFromData = useMemo(() => 
    pageTopSectionInfo
      ?.find(item => item.page
        ?.includes((window.location.pathname.replaceAll('/', '') || 'Home'))
      ), [pageTopSectionInfo]);
        
  return (<>
    <div className="row">
      {getFooterTopFromData && TopSection && <TopSection data={getFooterTopFromData} />}
    </div>
    <Comp copyright={pageInfo?.copyright} />
  </>)
}

const FooterThree = ({ copyright }) => {
  return (
    <>
      <div className="footer-style-2">
        {/* <CalltoActionEight /> */}
        <CopyrightTwo copyright={copyright} />
      </div>
      <ScrollTop />
    </>
  )
}
export default FooterThree;