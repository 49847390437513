import React, { useEffect, useState } from 'react';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { useLocation } from 'react-router-dom';
import { getAllBlogs } from '../services';

const BlogDetails = () => {
  const location = useLocation();
  const [blog, setBlog] = useState({});
  useEffect(() => {
    (async() => {
      try {
        const data = await getAllBlogs();
        const current = data?.find(item => item?.title?.replaceAll(' ', '-') === location.search.replace('?id=', ''));
        current && setBlog(current);
      } catch (e) {
        console.error(e);
      }
    })()
  },[]);

  return (
    <>
      <SEO title={`Blog Details - ${blog?.title || 'Teknikos'}`} />
      <Layout sympleFooter>
        <div className="rn-blog-details-area">
          {!!Object.keys(blog).length && <BlogDetailsContent data={blog}  />}
        </div>
      </Layout>
    </>
  )
}
export default BlogDetails;