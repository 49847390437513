import React from 'react';
import { getImg } from '../../services';

const BrandList = [
  {
    img: './images/brand/brand-01.png'
  },
  {
    img: './images/brand/brand-02.png'
  },
  {
    img: './images/brand/brand-03.png'
  },
  {
    img: './images/brand/brand-04.png'
  },
  {
    img: './images/brand/brand-05.png'
  },
  {
    img: './images/brand/brand-06.png'
  },
  {
    img: './images/brand/brand-07.png'
  },
  {
    img: './images/brand/brand-08.png'
  },
  {
    img: './images/brand/brand-01.png'
  },
  
]

const BrandThree = ({brandStyle, brandList=BrandList}) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {brandList.map((data, index) => (
        <li key={index}>
          <img src={getImg(data.img)} alt="Brand" />
        </li>
      ))}
    </ul>
  )
}

export default BrandThree;
