/* eslint-disable no-undef */
import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Splash from "./pages/Splash";
import Contact from "./elements/contact/Contact";
import AboutUs from "./pages/AboutUs";

// Elements import Here 

import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";
import DigitalStrategy from './pages/DigitalStrategy';
import SoftwareDevelopment from './pages/SoftwareDevelopment';
import StaffAugmentation from './pages/StaffAugmentation';


// Import Css Here 
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import HardwareSolutions from './pages/HardwareSolutions';
import Posts from './pages/Posts';
import Product from './pages/Product';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ErrorBoundary from './components/Error/Error';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <ErrorBoundary>
          <Switch>
            <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Splash}/>
            <Route path={`${process.env.PUBLIC_URL + "/DigitalStrategy"}`} exact component={DigitalStrategy}/>
            <Route path={`${process.env.PUBLIC_URL + "/SoftwareDevelopment"}`} exact component={SoftwareDevelopment}/>
            <Route path={`${process.env.PUBLIC_URL + "/StaffAugmentation"}`} exact component={StaffAugmentation}/>
            <Route path={`${process.env.PUBLIC_URL + "/HardwareSolutions"}`} exact component={HardwareSolutions}/>
            <Route path={`${process.env.PUBLIC_URL + "/DigitalStrategyRoadmap"}`} exact component={() => <Product pageName="digitalStrategyRoadmap" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/AIIntegrationNavigator"}`} exact component={() => <Product pageName="aIIntegrationNavigator" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/SoftwareDevelopmentIncubator"}`} exact component={() => <Product pageName="softwareDevelopmentIncubator" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/CustomSoftwareDevelopment"}`} exact component={() => <Product pageName="customSoftwareDevelopment" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/AIIntegrationandDevelopment"}`} exact component={() => <Product pageName="aIIntegrationandDevelopment" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/EmergingAndImmersiveExperiences"}`} exact component={() => <Product pageName="emergingAndImmersiveExperiences" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/DigitalSignage"}`} exact component={() => <Product pageName="digitalSignage" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/DevOps"}`} exact component={() => <Product pageName="devOps" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/ResourceScaling"}`} exact component={() => <Product pageName="resourceScaling" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/OffshoreDevelopment"}`} exact component={() => <Product pageName="offshoreDevelopment" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/SpecialityResources"}`} exact component={() => <Product pageName="specialityResources" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} exact component={BlogDetails}/>
            <Route path={`${process.env.PUBLIC_URL + "/ImmersiveTechnologyEnvisioning"}`} exact component={() => <Product pageName="immersiveTechnologyEnvisioning" />}/>
            <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} exact component={PrivacyPolicy}/>
            {/* Blog Part  */}
            <Route path={`${process.env.PUBLIC_URL + "/AboutUs"}`} exact component={AboutUs}/>
            <Route path={`${process.env.PUBLIC_URL + "/Posts"}`} exact component={Posts}/>
            <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                            
            <Route path="*" component={Error}/>
          </Switch>
        </ErrorBoundary>
      </PageScrollTop>
    </Router>
  )
}


export default App
