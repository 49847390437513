import React, { useEffect, useState } from 'react'
import FooterThree, { FooterWrapper } from '../common/footer/FooterThree';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import SlpitOne from '../elements/split/SlpitOne';
import ServiceTwo from '../elements/service/ServiceTwo';
import CalltoActionThree from '../elements/calltoaction/CalltoActionThree';
import SEO from '../common/SEO';
import Meta from '../components/Meta';
import { getDataFromDb, getImg } from '../services';



const DigitalStrategy = () => {
  const [pageInfo, setPageInfo] = useState({});

  useEffect(() => {
    (async () => {
      const data = await getDataFromDb({ collectionName: 'DigitalStrategy', documentId: 'QGsNFiQgvOGWt2W5KrDI' });
      setPageInfo(data);
    })()}, []);

  return (
    <>
      <main className="page-wrapper">
        <SEO title="digital strategy" />
        <Meta
          title="Your Digital Evolution Starts Here - Teknikos"
          description="Teknikos leads businesses to achieve, adapt, and scale at the pace of change. Explore our Digital Strategy solutions to accelerate your digital transformation journey."
          keywords="Digital Strategy, Digital Transformation, Technology Applications, Business Modernization, Technology Solutions, Digital Roadmap, AI Integration, Immersive Technology, Software Development, Consulting Services, Business Optimization, Predictable Timelines, Cost-effective Solutions, Digital Evolution, Innovative Technology, Intelligent Future, Solution Planning ,Customized Guidance, Teknikos Services"
        />
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-850">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h2 className="title titleSmall theme-gradient" dangerouslySetInnerHTML={{__html: pageInfo?.title}}></h2>
                  <p className="description">{pageInfo?.subTitle}</p>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img src={pageInfo?.bgImg ? getImg(pageInfo?.bgImg) : ''} alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.help?.orangeTitle}
                  title = {pageInfo?.help?.sectionTitle}
                  description = ""
                />
              </div>
            </div>
            <SlpitOne
              title={pageInfo?.help?.title}
              description={pageInfo?.help?.text}
              btnText={pageInfo?.help?.btnText}
              link=""
              url={pageInfo?.help?.img}
            />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {pageInfo?.solutions?.orangeTitle}
                  title = {pageInfo?.solutions?.title}
                  description = {pageInfo?.solutions?.text}
                />
              </div>
            </div>
            <ServiceTwo 
              serviceList={pageInfo?.solutions?.solutionsList}
              cardStyle = "card-style-1"
              textAlign = "text-start"
            />
          </div>
        </div>
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionThree} />
      </main>

    </>
  )
}

export default DigitalStrategy;