import React, { Component } from 'react';
import Error from '../../pages/Error';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by error boundary:', error, errorInfo);
    this.setState({ hasError: true });
  }

  componentDidMount() {
    // Global error handler
    window.onerror = (message, source, lineno, colno, error) => {
      console.error('Global error handler:', message, source, lineno, colno, error);
      this.setState({ hasError: true });
      return false; // Prevent the default browser error handling
    };
  }

  render() {
    if (this.state.hasError) {
      // You can customize the error UI or redirect to an error page
      return <Error title='Error' subTitle='An error has occured' btnText='Go Back Home' />
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children; 
  }
}

export default ErrorBoundary;
