/* eslint-disable no-debugger */
import React , {useState} from 'react';
import { EmailClient } from  '@azure/communication-email';
import usePageInfo from '../../common/hooks';
import { getImg } from '../../services';

const connectionString = "endpoint=https://websiteacs.unitedstates.communication.azure.com/;accesskey=C8ynpizSxehN+pfYPSp59GH2jtQn6sgMyrsP4kcgR11sR81I3TeeCsXIbDt+Qj2wwTMu93cvpIZiQnGgdLWU1w==";
const client = new EmailClient(connectionString);

const SuccessMessage = () => (
  <p className="success-message">Your message has been successfully sent. We will contact you soon.</p>
);

const ErrorMessage = () => (
  <p className="error-message">Sorry, there was an error sending your message. Please try again later.</p>
);

function ContactForm({ formStyle, fullForm, btnToCenter}) {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    companyName: '',
  });
  const { pageInfo } = usePageInfo('Images', 'NoyeXXAFrYwAxHFLk3Zt');

  const [result, setResult ] = useState('');
  const [loading, setLoading ] = useState(false);

  const salesEmail = "website-sales@teknikos.com";

  const sendEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // Email to the person filling out the form
      const userEmailMessage = {
        senderAddress: "sales@teknikos.com",
        content: {
          subject: `Teknikos is Here to Help - Contact Form Response to ${formData.fullname}`,
          html: `
            <p>Hello ${formData.fullname},</p>
            <p>Thank you for contacting us and expressing your interest in Teknikos.
            We will be reaching back out shortly.</p>
            <p>Thanks!<br>Team Teknikos</p>
            <img width="180" src=${pageInfo?.image && getImg(pageInfo?.image)} alt="logo" />
          `,
        },
        recipients: {
          to: [{ address: formData.email }],
        },
      };
  
      // Email to sales with form details
      const salesEmailMessage = {
        senderAddress: "DoNotReply@teknikos.com",
        content: {
          subject: `New Contact Form Submission from ${formData.fullname}`,
          html: `
            <p>New contact form submission details:</p>
            <p><strong>Full Name:</strong> ${formData.fullname}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Phone:</strong> ${formData.phone}</p>
            <p><strong>Company Name:</strong> ${formData.companyName}</p>
            <p><strong>Subject:</strong> ${formData.subject}</p>
            <p><strong>Message:</strong> ${formData.message}</p>
          `,
        },
        recipients: {
          to: [{ address: salesEmail }],
        },
      };
  
      // Send email to the person filling out the form
      const userPoller = await client.beginSend(userEmailMessage);
      await userPoller.pollUntilDone();
  
      // Send email to sales
      const salesPoller = await client.beginSend(salesEmailMessage);
      await salesPoller.pollUntilDone();
  
      setResult('success');
      setFormData({
        fullname: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        companyName: '',
      });
    } catch (e) {
      console.log("sale email = " + salesEmail);
      console.error(e);
      setResult('error');
    } finally {
      setLoading(false);
    }
    setTimeout(() => setResult(null), 5000);
  };

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      {fullForm ? (
        <>
          <div className="form-group">
            <input 
              type="text"
              name="fullname"
              placeholder="Your Name"
              required
              value={formData.fullname}
              onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
            />
          </div>

          <div className="form-group">
            <input 
              type="email"
              name="email"
              placeholder="Email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </div>

          <div className="form-group">
            <input 
              type="text"
              name="phone"
              placeholder="Phone Number"
              required
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
          </div>

          <div className="form-group">
            <input 
              type="text"
              name="companyName"
              placeholder="Your Company's Name"
              required
              value={formData.companyName}
              onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
            />
          </div>

          <div className="form-group">
            <input 
              type="text"
              name="subject"
              placeholder="Subject"
              required
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
            />
          </div>

          <div className="form-group">
            <textarea 
              name="message"
              placeholder="Your Message"
              required
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            >
            </textarea>
          </div>
        </>
      ) : (
        <>
          <div className="form-group">
            <input 
              type="text"
              name="fullname"
              placeholder="Your Name"
              required
              value={formData.fullname}
              onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
            />
          </div>
          <div className="form-group">
            <input 
              type="text"
              name="fullname"
              placeholder="Your Company's Name"
              required
              value={formData.companyName}
              onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <input 
              type="text"
              name="fullname"
              placeholder="Phone Number"
              required
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
          </div>
          <div className="form-group">
            <input 
              type="email"
              name="email"
              placeholder="Email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </div></>)}

      <div className={`form-group ${btnToCenter && 'd-flex justify-content-center'}`}>
        <button disabled={loading} className="btn-default btn-large">Submit</button>
      </div> 

      <div className="form-group">
        {result === 'success' ? <SuccessMessage /> : result === 'error' ? <ErrorMessage /> : null}
      </div> 
    </form>
  )
}
export default ContactForm;
