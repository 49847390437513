import React from 'react';
import { FiArrowRight } from "react-icons/fi";

const CalltoActionEight = ({ data }) => {
  const { title, text, link, btnText } = data || {};
  return (
    <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
      <div className="container">
        <div className="row row--0 align-items-center ">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title">{title}</h2>
                <h6 className="subtitle">{text}</h6>
                <div className="call-to-btn text-center mt--30">
                  <a className="btn-default btn-icon" href={link} rel="noreferrer">{btnText} <i className="icon"><FiArrowRight /></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CalltoActionEight;