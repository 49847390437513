import React, { useState } from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree, { FooterWrapper } from '../common/footer/FooterThree';
import SlipTwo from '../elements/split/SlipTwo';
import BlogPropTwo from '../components/blog/itemProp/BlogPropTwo';
import CalltoActionEight from '../elements/calltoaction/CalltoActionEight';
import Meta from '../components/Meta';
import { useEffect } from 'react';
import { getAllBlogs, getDataFromDb, getImg } from '../services';
import ServiceOne from '../elements/service/ServiceOne';

const Splash = () => {
  const [pageInfo, setPageInfo] = useState({});
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    (async() => {
      try {
        const data = await getAllBlogs()
        setBlogs(data?.filter(i => i.page === 'Home'));
      }catch(e) {
        console.error(e);
      }
    })()
  },[]);

  useEffect(() => { 
    (async () => {
      const data = await getDataFromDb({ collectionName: 'Home', documentId: 'x1c751IutBUhhR6IjQ9a' });
      setPageInfo(data);
    })()}, []);
    
  return (
    <>
      <SEO title="Teknikos" />
      <Meta
        title="Teknikos - Your Partner in Digital Evolution"
        description="Discover how Teknikos delivers transformative technology solutions to help your business thrive in a rapidly evolving landscape. Partner with us for success."
        keywords="Teknikos, Digital Evolution, Digital Transformation, Technology Solutions, Innovation, Expertise, Digital Strategy, Software Development, Staff Augmentation, Hardware Solutions, Partner in Success, Customized Software, User Experience, Efficiency, Strategic Guidance, Flexible Staffing, Hardware Design, Manufacturing, Logistics, 20-year Track Record"
      />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
        {/* Start Slider area  */}
        <div className="slider-area slider-style-2 variation-2  height-850 bg_image" style={{ backgroundImage: getImg(pageInfo?.bgImg, true) }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-6">
                <div className="inner text-start">
                  <h1 className="title display-one">{pageInfo?.title}</h1>
                  <h6 className="tag-title">{pageInfo?.subTitle}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  title = {pageInfo?.ourSpecialization?.title}
                  description = {pageInfo?.ourSpecialization?.text}
                />
              </div>
            </div>
            <ServiceOne 
              serviceStyle = "service__style--1 icon-circle-style"
              textAlign = "text-center"
              serviceList={pageInfo?.ourSpecialization?.specialization}
            />
          </div>
        </div>
        {/* End Service Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign = "text-center"
                  radiusRounded = ""
                  description = ""
                  title={pageInfo?.whyTeknikos?.sectionTitle}
                />
              </div>
            </div>
            <SlipTwo 
              title={pageInfo?.whyTeknikos?.title} 
              description={pageInfo?.whyTeknikos?.text} 
              data={pageInfo?.whyTeknikos?.statistics} 
              img={pageInfo?.whyTeknikos?.img} 
            />
          </div>
        </div>
        {/* End Elements Area  */}
        <div className="container">
          <div className="row mt_dec--30 mb--80">
            <BlogPropTwo data={blogs} column="col-lg-6 mt--30" StyleVarProp="box-card-style-default card-list-view" />
          </div>
        </div>
                    
        {/* <Separator /> */}

        {/* Start Our Demo  */}
        {/* <div className="rn-demo-area rn-section-gap" id="demo">
                    <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "All Demo Here"
                                    title = "Doob All Demo."
                                    description = "We create a business and consulting react template with 60+ elements features. <br /> 19+ demo pages, faster loading and well documentated code."
                                />
                            </div>
                        </div>
                        <div className="row row--30">
                            {Demo.map((data, index) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--40" key={index}>
                                    <div className="single-demo">
                                        <Link to={`${data.Url}`}>
                                            <div className="thumbnail">
                                                <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                                {data.badge ? <span className="label-badge">{data.badge}</span> : ''}
                                            </div>
                                        </Link>
                                        <h4 className="title"><Link to={`${data.Url}`}>{data.Title}</Link> </h4>
                                    </div>
                                </div>
                            ))} 
                        </div>
                    </div>
                </div>  */}
        {/* End Our Demo  */}


        {/* <Separator /> */}

        {/* Start Inner Pages  */}
        {/* <div className="rn-inner-pages rn-section-gap">
                    <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "All Others Pages"
                                    title = "All Others Pages."
                                    description = "Have a nice inner pages include in doob template."
                                    
                                />
                            </div>
                        </div>

                        <div className="row">
                            {InnerPages.map((data, index) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--40" key={index}>
                                    <div className="single-demo">
                                        <Link to={`${data.Url}`}>
                                            <div className="thumbnail">
                                                <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                            </div>
                                        </Link>
                                        <h4 className="title"><Link to={`${data.Url}`}>{data.Title}</Link></h4>
                                    </div>
                                </div>
                            ))} 
                        </div>

                    </div>
                </div> */}
        {/* End Inner Pages  */}
        <FooterWrapper Comp={FooterThree} TopSection={CalltoActionEight} />
        {/* <FooterThree /> */}
      </main>

    </>
  )
}

export default Splash;