import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { getImg } from '../../services';
import { getSizeOfBiggerElement } from '../../utils';

const TeamTwo = ({column , teamStyle, teamData}) => {
  const parentElem = useRef(null);
  const [size, setSize] = useState(0);

  const measureSize = useCallback(() => {
    setSize(0);
    (() => {
      if (parentElem.current && !size) {
        const allChildren = Array.from(parentElem.current.children);
        const newSize = getSizeOfBiggerElement(allChildren);
        setSize(newSize);
      }
    })()
  }, [parentElem.current]);
  
  useEffect(() => {

    if (teamData?.length) setTimeout(() => measureSize(), 200); // Initial measurement

    window.addEventListener('resize', measureSize);

    return () => {
      window.removeEventListener('resize', measureSize);
    };
  }, [teamData]);

  return (
    <div className="row row--15" ref={parentElem}>
      {teamData?.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
            <div className={`rn-team ${teamStyle}`}>
              <div className="inner" style={size ? { height: `${size}px`} : {}}>
                <figure className="thumbnail">
                  <img src={data?.img ? getImg(data?.img) : ''} alt="Corporate React Template" />
                </figure>
                <figcaption className="content">
                  <h2 className="title">{data.fullName}</h2>
                  <h6 className="subtitle theme-gradient">{data.position}</h6>
                  <p className="description">{data.description}</p>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  )
}

export default TeamTwo;
